/** @format */

.row {
	display: flex;
	align-items: center;
	justify-content: center;
}

:root {
	--background-dark: #2d3548;
	--text-light: rgba(255, 255, 255, 0.6);
	--text-lighter: rgba(255, 255, 255, 0.9);
	--spacing-s: 8px;
	--spacing-m: 16px;
	--spacing-l: 24px;
	--spacing-xl: 32px;
	--spacing-xxl: 164px;
	--width-container: 1200px;
}

.card:hover .card__background {
	transform: scale(1.05) translateZ(0);
}

.card_grid:hover > .card:not(:hover) .card__background {
	filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
}

.card_grid:hover > .card:not(:hover) .card__content > h2 {
	color: transparent;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.card__content {
	left: 0;
	padding: var(--spacing-l);
	position: absolute;
	top: 0;
}

.card__category {
	color: var(--text-light);
	font-size: 0.9rem;
	margin-bottom: var(--spacing-s);
	text-transform: uppercase;
}

.card__heading {
	color: var(--text-lighter);
	font-size: 1.9rem;
	text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
	line-height: 1.4;
	word-spacing: 100vw;
}
